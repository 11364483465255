import React from 'react';
import { Helmet } from 'react-helmet';

import DefaultLayout from '../layouts';
import ToggleBox from '../components/toggle-box';
import Metadata from '../components/metadata';

import * as styles from './2021-venla-taavitsainen.module.css';

import titleImg from '../assets/2021/logo_back_web.svg';
import boatImg from '../assets/2021/boat.svg';

import buutti from '../assets/2021/ads/buutti.svg';
import etasku from '../assets/common/ads/etasku.png';
import insta from '../assets/2021/ads/insta.svg';
import nokianPanimo from '../assets/2021/ads/nokian_panimo.svg';
import riemurinne from '../assets/common/ads/riemurinne_netti.png';
import tamppi from '../assets/common/ads/tamppi.png';
import tek from '../assets/common/ads/tek.svg';

// --- all required data definitions below  ---

const sponsors = [
  { href: 'https://buutti.com/', src: buutti, title: 'Buutti' },
  { href: 'https://www.etasku.fi/', src: etasku, title: 'eTasku' },
  { href: 'https://www.insta.fi/', src: insta, title: 'Insta' },
  {
    href: 'https://nokianpanimo.fi/',
    src: nokianPanimo,
    title: 'Nokian Panimo',
  },
  { href: 'https://www.riemurinne.fi/', src: riemurinne, title: 'Riemurinne' },
  { href: 'https://www.tamppi.fi/', src: tamppi, title: 'Tamppi' },
  { href: 'https://www.tek.fi/fi', src: tek, title: 'TEK' },
];

const crew = [
  {
    title: 'Ohjaaja',
    members: ['Jari Leppänen'],
  },
  {
    title: 'Tuottajat',
    members: [
      'Enni Hartikainen',
      'Katja Kujala',
      'Jari Leppänen',
      'Janita Rintala',
    ],
  },
  {
    title: 'Bändi',
    members: [
      <strong>Niko Gullsten</strong>,
      <strong>Taneli Hongisto</strong>,
      'Heidi Hansen-Haug',
      'Jussi Hänninen',
      'Kuura Janhunen',
      'Emmi Kekäle',
      'Lauri Leinonen',
      'Petteri Niemi',
      'Enni Sipilä',
      'Jussi Uusitalo',
      'Juho Viitala',
    ],
  },
  {
    title: 'Keikkapaikkatiimi',
    members: [
      <strong>Hari Nortunen</strong>,
      <strong>Maisa Virtanen</strong>,
      'Ari Alanko',
      'Viliina Huttunen ',
      'Riina Lahtinen ',
      'Sanni Laine',
      'Erika Kaski',
      'Mika Pajala',
    ],
  },
  {
    title: 'Käsikirjoitus',
    members: [
      <strong>Peitsa Leinonen</strong>,
      <strong>Hari Nortunen</strong>,
      'Alfred Altsten',
      'Juho Jokelainen',
      'Sanna Kallio',
      'Riina Lahtinen',
      'Severi Mäkelä',
      'Juho Mäntysaari',
      'Maisa Virtanen',
    ],
  },
  {
    title: 'Laulunopettaja',
    members: ['Jenni Arponen'],
  },
  {
    title: 'Lavastus',
    members: [
      <strong>Pauli Oikkonen</strong>,
      'Taneli Akkanen',
      'Ari Alanko',
      'Erik Erola',
      'Sami Hartala',
      'Sakari Huuskola',
      'Juho Jokelainen',
      'Anu Juvonen',
      'Kalle Karttunen',
      'Antti Kemppi',
      'Henri Kiiski',
      'Miika Kotajärvi',
      'Riina Konola',
      'Matti Kujala',
      'Justus Leppäaho',
      'Alma Mustonen',
      'Juho Mäntysaari',
      'Aku Niskanen',
      'Leo Norosoja',
      'Ville Nousiainen',
      'Lauri Pitkäjärvi',
      'Siina Siuvo',
      'Kiti Suupohja',
      'Oona Vaitti',
    ],
  },
  {
    title: 'Logistiikka',
    members: [
      <strong>Erik Erola</strong>,
      'Ari Alanko',
      'Sami Hartala',
      'Aku Niskanen',
      'Pauli Oikkonen',
      'Lauri Pitkäjärvi',
    ],
  },
  {
    title: 'Mainosmyynti',
    members: [<strong>Jani Patrakka</strong>, 'Antti Kemppi'],
  },
  {
    title: 'Markkinointi',
    members: [
      <strong>Jenna Jalkanen</strong>,
      'Alfred Altsten',
      'Katariina Jokinen ',
      'Hari Nortunen',
      'Jasmin Äijälä',
    ],
  },
  {
    title: 'Näyttämömestari',
    members: ['Janita Rintala'],
  },
  {
    title: 'Näyttelijät',
    members: [
      'Emma Brown',
      'Suvi Huotari',
      'Anttu Kaipainen',
      'Jenni Nerg',
      'Saga Porjo',
    ],
  },
  {
    title: 'Puvustus',
    members: [
      <strong>Maisa Virtanen</strong>,
      'Emma Brown',
      'Minna Färm',
      'Heidi Hansen-Haug',
      'Juho Jokelainen',
      'Sanna Kallio',
      'Alexandra Karppinen',
      'Erika Kaski',
      'Miika Kotajärvi',
      'Matti Kujala',
      'Riina Lahtinen',
      'Sanni Laine',
      'Santtu Laine',
      'Venla Lähteenlahti',
      'Alma Mustonen',
      'Linnea Niemelä',
      'Niina Palmu',
      'Minna Rajala',
      'Lisa Salo',
      'Siina Siuvo',
      'Hanna Suhonen',
      'Krista Uusi-Kinnala',
    ],
  },
  {
    title: 'Sanoitus',
    members: [
      <strong>Miikka Saraniemi</strong>,
      'Jenni Arponen',
      'Juho Jokelainen',
      'Mikko Karintaus',
      'Kati Pöyhönen',
      'Pyry Qvick',
      'Mikko Tiitola',
    ],
  },
  {
    title: 'Taikatiimi',
    members: [
      <strong>Milla Mäenhovi</strong>,
      <strong>Henna Salosyrjä</strong>,
      'Carita Kujala',
      'Veera Lignell',
      'Reetta Liinamaa',
      'Minna Rajala',
      'Hanna Suhonen',
      'Heidi Tuomela',
    ],
  },
  {
    title: 'Taltiointi',
    members: [
      <strong>Juha-Matti Hakojärvi</strong>,
      'Aleksi Eilo',
      'Jan Heikkilä',
      'Mika Kiviluoma',
      'Mikko Kohonen',
      'Mika Kuitunen',
      'Justus Leppäaho',
      'Juho Pyykkönen',
      'Siina Siuvo',
      'Aleksi Sivonen',
      'Aaro Tuukkanen',
      'Jarno Tuokkola',
    ],
  },
  {
    title: 'Tanssijat / koreografit',
    members: [
      <strong>Meiju Koivuniemi</strong>,
      <strong>Siina Siuvo</strong>,
      'Lauri Ahlqvist',
      'Emma Jokela',
      'Alexandra Karppinen',
      'Anni Lehtimäki',
      'Lisa Salo',
    ],
  },
  {
    title: 'Tarpeisto',
    members: [
      <strong>Lauri Kuusisto</strong>,
      <strong>Lauri Pitkäjärvi</strong>,
      'Ari Alanko',
      'Emma Brown',
      'Lasse Enäsuo',
      'Juha-Matti Hakojärvi',
      'Heidi Hansen-Haug',
      'Sami Hartala',
      'Juho Jokelainen',
      'Anu Juvonen',
      'Sanna Kallio',
      'Riina Konola',
      'Matti Kujala',
      'Kaisa Laaksonen',
      'Sanni Laine',
      'Esa Lakaniemi',
      'Severi Mäkelä',
      'Hari Nortunen',
      'Niina Palmu',
      'Anni Silvo',
      'Oona Vaitti',
      'Maisa Virtanen',
    ],
  },
  {
    title: 'Tekniikka',
    members: ['Erik Erola', 'Sami Hartala', 'Anttu Kaipainen', 'Mikko Kohonen'],
  },
  {
    title: 'Velhot',
    members: [
      <strong>Henri Kiiski</strong>,
      'Sami Hartala',
      'Juho Jokelainen',
      'Matti Kujala',
      'Ville Nousiainen',
    ],
  },
  {
    title: 'Visualistit',
    members: [
      <strong>Minna Färm</strong>,
      'Aleksi Eilo',
      'Peitsa Leinonen',
      'Vivian Lunnikivi',
      'Jaakko Rinta-Filppula',
      'Anni Silvo',
    ],
  },
  {
    title: 'WWW',
    members: ['Vivian Lunnikivi', 'Jaakko Rinta-Filppula'],
  },
];

const shows = [
  {
    city: 'Tampere I',
    building: 'Messukylän työväentalo',
    date: 'la 27.11.',
    time: '14',
    href: 'https://kauppa.naaspeksi.fi/naaspeksi/naaspeksi2021/',
  },
  {
    city: 'Tampere II',
    building: 'Messukylän työväentalo',
    date: 'la 27.11.',
    time: '19',
    href: 'https://kauppa.naaspeksi.fi/naaspeksi/naaspeksi2021/',
  },
  {
    city: 'Tampere III',
    building: 'Messukylän työväentalo',
    date: 'su 28.11.',
    time: '14',
    href: 'https://kauppa.naaspeksi.fi/naaspeksi/naaspeksi2021/',
  },
  {
    city: 'Katso esitys ilmaiseksi netistä',
    building: 'Kaikki näytökset lähetetään suorana osoitteessa',
    date: '',
    time: '',
    href: 'https://twitch.tv/naaspeksi',
  },
];

// --- all required data definitions above  ---

const Raina2021 = ({ location }) => (
  <DefaultLayout>
    <Helmet htmlAttributes={{ class: styles.root2021 }} />

    <Metadata
      page={{
        title: 'Venla Taavitsainen – Johtolankoja kehräämässä',
        excerpt: 'Lorem ipsum dolor sit amet',
      }}
      location={location}
    />

    <InfoBoard />
  </DefaultLayout>
);

const InfoBoard = () => {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.infoBoard}>
        <Header style={{ margin: '0em 0em 3em 0em' }} />
        <SynopsisColumn />
        <ShowsColumn shows={shows} />
        <Crew crew={crew} />
        <Sponsors data={sponsors} />
        <img
          src={boatImg}
          className={styles.boat}
          alt="nineteen thirties passenger cruise ship"
        />
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <header>
      <img
        src={titleImg}
        alt="Venla Taavitsainen"
        title="Venla Taavitsainen"
        className={styles.titleImg}
        height="17rem"
      />
    </header>
  );
};

const SynopsisColumn = () => {
  return (
    <div className={styles.contentPadding}>
      <p>
        Vuoden 2021 NääsPeksi kutsuu katsojansa seilaamaan S/S Oolannilla kohti
        valoisampaa tulevaisuutta. On vuosi 1935, ja muutoksen tuulet puhaltavat
        lamasta nousevalla Euroopan mantereella. Työt loppuivat tukkilikka Venla
        Taavitsaiselta, mutta se ei hidasta tätä aina valmista ja vikkelää
        vihurituulta, joka lähtee Ruotsiin etsimään uutta elämää. Keksijä Unelma
        Satakivi on matkalla Uppsalan konferenssiin esittelemään mullistavaa
        keksintöään, joka herättää kaikkien kanssamatkustajien kiinnostuksen.
      </p>
      <p>
        Tapahtuu rikos. Venla lähtee selvittämään. Punotaanko kehruuautomaatilla
        oikea johtolanka? Kenellä on viimeinen viskipullo? Ja missä särjen
        suolessa luuraa perämies Mattson?
      </p>
      <p>
        NääsPeksin kaikkien aikojen 23. produktio vie katsojansa vaarallisille
        vesille. Luvassa on seikkailuja, salajuonia sekä sateenvarjoja
        kaurapuuron päälle. Älä jää rannalle, vaan osta liput syksyn
        vauhdikkaimpaan spektaakkeliin!
      </p>
    </div>
  );
};

const ShowsColumn = ({ shows }) => {
  return (
    <div className={styles.contentPadding}>
      <h2>Esitykset</h2>

      <div className={styles.eventList}>
        {shows.map((show, i) => {
          return <Show details={show} i={i} />;
        })}
      </div>

      <p>
        Pääsylippu maksaa työssäkäyville 15€ ja muille (opiskelijat, työttömät,
        eläkeläiset, varushenkilöt, tms.) 10€.
      </p>

      <p>
        Kaikki näytökset alkavat tasatunnein ja kestävät väliajan kanssa noin
        kaksi tuntia. Ovet aukeavat noin 30 minuuttia ennen esitysten alkua.
        Salin istumapaikat täytetään vapaasti saapumisjärjestyksessä. Lippuihin
        liittyvissä kysymyksissä ota yhteyttä liput@naaspeksi.fi.
      </p>
    </div>
  );
};

const Show = ({ details }) => (
  <a className={styles.event} href={details.href}>
    <div
      className={styles.eventTicketTitle}
      style={{ marginBottom: '1rem' }}
      aria-hidden={true}
    >
      1. luokan lippu
    </div>
    <h3>{details.city}</h3>
    <div
      style={details.date !== '' ? { display: 'none' } : {}}
      className={styles.eventLine}
    >
      <span>
        {details.building} <a href="https://naaspeksi.fi/">naaspeksi.fi</a>
      </span>
    </div>
    <div
      style={details.date === '' ? { display: 'none' } : {}}
      className={styles.eventLine}
    >
      <span className={styles.eventLineTitle}>Kohde:</span>{' '}
      <span>{details.building}</span>
    </div>
    <div
      style={details.date === '' ? { display: 'none' } : {}}
      className={styles.eventLine}
    >
      <span className={styles.eventLineTitle}>Lähtö:</span>{' '}
      <span>
        {details.date} klo {details.time}
      </span>
    </div>
    <div
      className={styles.eventTicketTitle}
      style={{ marginTop: '1rem', textTransform: 'none' }}
      aria-hidden={true}
    >
      S/S Oolanti
    </div>
  </a>
);

const CrewSection = ({ title, members }) => (
  <ToggleBox
    title={title}
    defaultOpen={false}
    titleClassName={styles.toggleTitle}
    className={styles.toggleBox}
  >
    <ul>
      {members.map((member) => (
        <li id={`${title}-${member}`}>{member}</li>
      ))}
    </ul>
  </ToggleBox>
);

const Crew = ({ crew }) => (
  <div className={styles.contentPadding}>
    <h2>Tekijät</h2>

    <div className={styles.toggleBoxGrid}>
      <div className={styles.columnInner}>
        {crew.slice(0, Math.ceil(crew.length / 3)).map((section) => (
          <CrewSection title={section.title} members={section.members} />
        ))}
      </div>

      <div className={styles.columnInner}>
        {crew
          .slice(Math.ceil(crew.length / 3), -Math.floor(crew.length / 3))
          .map((section) => (
            <CrewSection title={section.title} members={section.members} />
          ))}
      </div>

      <div className={styles.columnInner}>
        {crew.slice(-Math.floor(crew.length / 3)).map((section) => (
          <CrewSection title={section.title} members={section.members} />
        ))}
      </div>
    </div>
  </div>
);

const Sponsors = ({ data }) => (
  <div className={styles.contentPadding}>
    <h2>Yhteistyössä</h2>

    <div className={styles.adsList}>
      {data.map((sponsor) => (
        <Sponsor href={sponsor.href} src={sponsor.src} title={sponsor.title} />
      ))}
    </div>
  </div>
);

const Sponsor = ({ href, src, title }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <img src={src} alt={title} title={title} />
  </a>
);

export default Raina2021;
